import { useState } from "react";
import { CircularProgress } from "@mui/material";

const OosDashboard = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/e3c7f935-5065-422e-9b10-ce93595b10f3/page/FvH5D"
        frameBorder="0"
        style={{
          border: "none",
          display: loading ? "none" : "block",
        }}
        title="Cases Packed Analytics"
        onLoad={handleLoad}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </>
  );
};

export default OosDashboard;

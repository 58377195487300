import { Route, Switch } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LoginPage from "./pages/Login";
import React from "react";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import ProductImages from "./pages/ProductImages";
import AddUsers from "./pages/AddUsers";
import PrivateRoute from "./components/routes/privateRoute";
import UserProfile from "./pages/UserProfile";
import Tasks from "./pages/addTask";
import "../src/components/styles/index.css";
import { UserProvider } from "./components/context/userAuth";
import MainLayout from "./pages/global/layout";
import { SalesRepProvider } from "./components/context/salesfetch";
import Routes from "./pages/manageRoutes";
import { RoutesProvider } from "./components/context/routesfetch";
import Stores from "./pages/manageStores";
import { StoresProvider } from "./components/context/storesfetch";
import { BrandsProvider } from "./components/context/brandsfetch";
import Brands from "./pages/manageBrands";
import Team from "./pages/manageTeam";
import AdminProfile from "./pages/AdminProfile";
import VisitsDashboard from "./pages/dashboard/visitAnalytics";
import Surveys from "./pages/manageSurveys";
import AdminRoute from "./components/routes/adminRoute";
import CasesDashboard from "./pages/dashboard/casePacked";
import PlanogramDashboard from "./pages/dashboard/planogram";
import DisplayDashboard from "./pages/dashboard/display";
import VisibilityDashboard from "./pages/dashboard/visibility";
import ExpiredDashboard from "./pages/dashboard/expired";
import OosDashboard from "./pages/dashboard/oos";
import SosDashboard from "./pages/dashboard/sos";
import PricesDashboard from "./pages/dashboard/prices";
import GalleryVisits from "./pages/manageGallery";
import GalleryImages from "./pages/manageGalleryImages";

function App() {
  return (
    <div className="app">
      <ToastContainer />
      <UserProvider>
        <SalesRepProvider>
          <RoutesProvider>
            <StoresProvider>
              <BrandsProvider>
                <BrowserRouter>
                  <div className="flex flex-col h-screen">
                    <Switch>
                      <Route exact path="/login" component={LoginPage} />

                      <MainLayout>
                        <PrivateRoute
                          exact
                          path="/visitAnalytics"
                          component={VisitsDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/casesPacked"
                          component={CasesDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/oosSasa"
                          component={OosDashboard}
                        />
                        <PrivateRoute
                          excat
                          path="/pricesSasa"
                          component={PricesDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/sosSasa"
                          component={SosDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/planogramSasa"
                          component={PlanogramDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/expiredSasa"
                          component={ExpiredDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/visibilitySasa"
                          component={VisibilityDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/displaySasa"
                          component={DisplayDashboard}
                        />
                        <AdminRoute exact path="/" component={Team} />

                        <AdminRoute exact path="/users" component={AddUsers} />

                        <AdminRoute exact path="/addvisit" component={Tasks} />
                        <PrivateRoute
                          exact
                          path="/surveys"
                          component={Surveys}
                        />
                        <AdminRoute
                          exact
                          path="/productimages"
                          component={ProductImages}
                        />
                        <PrivateRoute
                          exact
                          path="/visitsGallery"
                          component={GalleryVisits}
                        />
                        <PrivateRoute
                          exact
                          path="/visitsImages"
                          component={GalleryImages}
                        />
                        <PrivateRoute
                          exact
                          path="/profile"
                          component={AdminProfile}
                        />
                        <AdminRoute exact path="/routes" component={Routes} />
                        <AdminRoute
                          exact
                          path="/userprofile/:userid/:username"
                          component={UserProfile}
                        />
                        <AdminRoute exact path="/stores" component={Stores} />
                        <AdminRoute exact path="/brands" component={Brands} />
                      </MainLayout>
                    </Switch>
                  </div>
                </BrowserRouter>
              </BrandsProvider>
            </StoresProvider>
          </RoutesProvider>
        </SalesRepProvider>
      </UserProvider>
    </div>
  );
}

export default App;

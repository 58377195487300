import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { dev_url, prod_url, local_url } from "../../apiurls";
import { toast } from "react-toastify";

export const login = async (values) => {
  try {
    const { data } = await axios.post(
      // `${prod_url}/login`,
      `${dev_url}/login`,

      values
    );
    return data.data;
  } catch (error) {
    return error;
  }
};
/////////////// Request Otp to 2Step Verfication Api call
export const requestOtpForMultiAuthFactor = async (email) => {
  try {
    const response = await axios.post(`${dev_url}/requestLoginOtp`, {
      email,
    });

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
/////////////// Request Otp to reset Password Api call
export const requestOtpForForgettenPassword = async (email) => {
  try {
    const response = await axios.post(`${dev_url}/requestOtp`, { email });

    if (response.data.success && response.data.otp) {
      // Store the OTP in a cookie with a 10-minute expiration
      const tenMinutesFromNow = new Date(new Date().getTime() + 10 * 60 * 1000);
      Cookies.set("Price_App_OTP", response.data.otp, {
        expires: tenMinutesFromNow,
      });
    }

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
//////////////////////////////////////////////////////////////
/////////////////////////// ***************Function to add user*****************///////////////////////////////////////
export const AddUser = async (values) => {
  try {
    const response = await axios.post(
      // `${prod_url}/allusers`,
      `${dev_url}/allusers`,

      values,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

/////////////////////////////////////////////////// function to change the status of the sales rep accound
export const changeStatus = async (user_id, data) => {
  try {
    const response = await axios.put(
      // `${prod_url}/changeStatus/${user_id}`,
      `${dev_url}/changeStatus/${user_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

//////////////////////// ************************function to fetch sales reps based on admin's country************************////////////////////

export const fetchSalesRep = async (
  setIsLoading,
  setUsers,
  setFilteredUsers,
  usersDeleted
) => {
  setIsLoading(true);

  try {
    const { user_country, user_id } = jwtDecode(Cookies.get("Price_App_Token"));

    const response = await axios.get(
      // `${prod_url}/allusers?user_country=${user_country}`,
      `${dev_url}/allusers?user_country=${user_country}&users_deleted=${usersDeleted}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Map the response data to include rowNumber
    if (response.data && Array.isArray(response.data.data)) {
      let UsersData = response.data.data
        .filter((user) => user.user_id !== user_id)
        .map((user, index) => ({
          id: user.user_id,
          ...user,
          rowNumber: index + 1,
        }));

      setUsers(UsersData);
      setFilteredUsers(UsersData);
    } else {
      // Handle the case where no users are found or the data format is unexpected
      setUsers([]);
      setFilteredUsers([]);
    }
  } catch (error) {
    console.error("Error fetching Users:", error);
    setUsers([]);
    setFilteredUsers([]);
  } finally {
    setIsLoading(false);
  }
};

//////////////// Restoring Sales Rep
export const RestoreUsers = async (user_id) => {
  const data = {};
  try {
    const response = await axios.put(
      // `${prod_url}/user/${user_id}`,
      `${dev_url}/restoreuser/${user_id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
///////////// **********************Deleting sales reps or an admin******************************************/////////////////////////
export const deleteUsers = async (user_id) => {
  try {
    const response = await axios.delete(
      // `${prod_url}/user/${user_id}`,
      `${dev_url}/user/${user_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
///////////// **********************editing sales reps or an admin******************************************/////////////////////////
export const editUsers = async (data, user_id) => {
  try {
    const response = await axios.put(
      // `${prod_url}/user/${user_id}`,
      `${dev_url}/user/${user_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};
///////////// **********************getting sales rep data******************************************/////////////////////////
export const profileDataApiCall = async (user_id, setDataLoading) => {
  setDataLoading(true);
  try {
    const response = await axios.get(
      // `${prod_url}/user/${user_id}`,
      `${dev_url}/user/${user_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  } finally {
    setDataLoading(false);
  }
};

///////////// **********************editing user logged in and save the new token ******************************************/////////////////////////
export const editUserLoggedIn = async (data, user_id) => {
  try {
    const response = await axios.put(
      // `${prod_url}/user/${user_id}`,
      `${dev_url}/adminedit/${user_id}`,
      // `${local_url}/adminedit/${user_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    Cookies.remove("Price_App_Token");

    Cookies.set("Price_App_Token", response.data.token);

    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};
///////////////// Reset Forgotten Password Api Call
export const resetForgottenPassword = async (email, password) => {
  try {
    const response = await axios.post(`${dev_url}/resetForgettonPassword`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        message: error.response.data.message || "An error occurred",
        success: false,
        status: error.response.status,
      };
    } else if (error.request) {
      return {
        message: "No response received from server",
        success: false,
      };
    } else {
      return {
        message: error.message || "An error occurred",
        success: false,
      };
    }
  }
};
////////////////////// check the otp for login
export const verifyOtp = async (email, otp) => {
  try {
    const response = await axios.post(`${dev_url}/otpCheck`, {
      email,
      otp,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

//////////// download the skus_excel sheet

export const downloadUsersListExcel = async () => {
  try {
    const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));
    const response = await axios.get(
      // `${prod_url}/usersExcelSheet`,
      `${dev_url}/usersExcelSheet/${user_country}`,
      // `${local_url}/usersExcelSheet`,

      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Create a URL for the Excel blob
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Users_List.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Show success message
    toast.success("File downloaded successfully");
  } catch (e) {
    if (!e.response) {
      toast.error("Network error. Please check your connection and try again.");
      return {
        message: "Network error. Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      toast.error("A server error occurred. Please try again later.");
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      toast.error(e.response.data.message || "An error occurred");
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { dev_url, local_url, prod_url } from "../../apiurls";

// ///////////////////////////// Function to get all routes details in this country
export const fetchCountryRoutes = async (
  setIsLoading,
  setRoutes,
  setFilteredRoutes,
  routesDeleted
) => {
  setIsLoading(true);

  try {
    const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));

    const response = await axios.get(
      // `${prod_url}/country_routes/${user_country}`,
      `${dev_url}/country_routes/${user_country}?routes_deleted=${routesDeleted}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    if (response.data && Array.isArray(response.data.data)) {
      let RoutesData = response.data.data.map((user, index) => ({
        id: user.route_id,
        ...user,
        rowNumber: index + 1,
      }));
      setRoutes(RoutesData);
      setFilteredRoutes(RoutesData);
    } else {
      setRoutes([]);
      setFilteredRoutes([]);
    }
  } catch (error) {
    setRoutes([]);
    setFilteredRoutes([]);
    console.error("Error fetching Users:", error);
  } finally {
    setIsLoading(false);
  }
};

////// function to add Route////////////////////
export const AddRoute = async (values) => {
  try {
    const response = await axios.post(
      // `${prod_url}/addroute`,
      `${dev_url}/addroute`,

      values,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

/////////// function to get stores for the route to be edited
export const RouteStores = async (
  route_id,
  setCustomerswithRouteId,
  setCustomerswithoutRouteId,
  setLoading
) => {
  try {
    const response = await axios.get(
      // `${prod_url}/route_modifications/${route_id}`,
      `${dev_url}/route_modifications/${route_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    setCustomerswithRouteId(response.data.data_with_route);
    setCustomerswithoutRouteId(response.data.data_without_route);
    // const { message } = response.data;
    // return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  } finally {
    setLoading(false);
  }
};
/////////////////// Function to edit the route name or route app id
export const editRoute = async (values, route_id) => {
  try {
    const response = await axios.post(
      // `${prod_url}/route_modifications/${route_id}`,
      `${dev_url}/route_modifications/${route_id}`,

      values,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error. Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

///////////// **********************getting sales rep data******************************************/////////////////////////
export const routeprofileData = async (route_id, setDataLoading) => {
  setDataLoading(true);
  try {
    const response = await axios.get(
      // `${prod_url}/route_modifications/${route_id}`,
      `${dev_url}/route_modifications/${route_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    return response.data.data;
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  } finally {
    setDataLoading(false);
  }
};

/////////////////////// changing status for route
export const changeRouteStatus = async (route_id, data) => {
  try {
    const response = await axios.put(
      // `${prod_url}/routeStatus/${route_id}`,
      `${dev_url}/routeStatus/${route_id}`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

//////////////// Restoring Routes
export const RestoreRoute = async (route_id) => {
  const data = {};
  try {
    const response = await axios.put(
      `${dev_url}/restoreroute/${route_id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
///////////// **********************Deleting Routes******************************************/////////////////////////
export const deleteRoutes = async (route_id) => {
  try {
    const response = await axios.delete(
      `${dev_url}/route_modifications/${route_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    const { message } = response.data;
    return { message };
  } catch (e) {
    return {
      message: e.response ? e.response.data.message : "An error occurred",
    };
  }
};
